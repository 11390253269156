<template>
  <KRDNAWizard :claim="claim" />
</template>

<script>
import { mapState } from 'vuex'
import KRDNAWizard from './KRDNAWizard.vue'

export default {
  components: {
    KRDNAWizard,
  },
  data() {
    return { claimId: this.$route.params.id || 0 }
  },
  mounted() {
    this.$store.dispatch('mtpl/getClaim', this.claimId)
  },
  computed: {
    ...mapState({
      claim: (state) => state.mtpl.claim,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
